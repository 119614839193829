import { NavLink } from "@remix-run/react";
import { Home } from "lucide-react";

import pageErrorImage from "../../assets/page-error.svg";
import { Button } from "../ui/button";

/**
 * This component is for displaying high-level status of a page. Currently it is used for 404 and 500 errors.
 */

export function PageStatus({
  title,
  message,
}: {
  title: string;
  message: string;
}) {
  return (
    <div className="flex flex-col items-center">
      <img src={pageErrorImage} alt={title} />
      <h2 className="mb-3 mt-4 text-4xl font-medium text-zinc-950">{title}</h2>
      <p className="text-xl text-zinc-500">{message}</p>
      <NavLink to="/">
        <Button className="my-8 bg-blue-700 text-base font-medium">
          <Home className="mr-2 size-4" /> Back to Dashboard
        </Button>
      </NavLink>
      <p className="text-sm text-zinc-500">
        Alternatively you can{" "}
        <a className="text-blue-600 underline" href="mailto:tom@sutro.xyz">
          contact
        </a>{" "}
        us to get assistance
      </p>
    </div>
  );
}
