import "./tailwind.css";

import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import * as Sentry from "@sentry/browser";
import {
  SutroApiProvider,
  useAuthenticatedSutroApi,
} from "@sutro/studio2-quarantine/sutro-api/api-provider";
import { PropsWithChildren, Suspense, useEffect, useRef } from "react";
import { isSutroError, logError } from "sutro-common";
import { pick } from "sutro-common/collection-helpers/pick";
import { OpenTelemetry } from "sutro-common/observability/OpenTelemetry";
import { useEventListener } from "usehooks-ts";
import { useShallow } from "zustand/react/shallow";

import config from "~/app.config";
import { PageContent } from "~/components/app/page-content";
import { PageStatus } from "~/components/app/page-status";
import {
  BelowNavigationBarFullSizeContainer,
  StandardTopNavigationBar,
} from "~/components/app/top-navigation-bar";
import { Toaster } from "~/components/ui/sonner";
import { useStudio } from "~/lib/hooks/use-studio";
import { isUserLoggedIn } from "~/lib/is-user-logged-in";
import { retrieveAuthData } from "~/lib/studio-local-storage";
import { PreviewCommLayerProvider } from "~/lib/use-preview-comm";
import { EntitlementsProvider } from "~/providers/EntitlementsProvider/entitlements-provider";
import { IntercomProvider } from "~/providers/IntercomProvider/intercom-provider";

export const links: LinksFunction = () => {
  return [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    { rel: "manifest", href: "/site.webmanifest" },
    { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#5bbad5" },
  ];
};

export const meta: MetaFunction = () => {
  return [
    { name: "msapplication-TileColor", content: "#da532c" },
    { name: "theme-color", content: "#ffffff" },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body suppressHydrationWarning>
        <div className="size-full">{children}</div>
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

const PUBLIC_URLS = [
  "/login",
  "/create",
  "/signup",
  "/reset-password",
  "/change-password",
];

/***
 * A little unorthodox, but this is the only way I (Dan) could
 * think of to ensure that the api gets initialized correctly, regardless
 * of the route that is being rendered.
 */
const InitializeApi = ({ children }: PropsWithChildren) => {
  const authApi = useAuthenticatedSutroApi();
  const { authApi: studioApi, setAuthApi } = useStudio(
    useShallow(pick("setAuthApi", "authApi"))
  );

  useEffect(() => {
    if (!studioApi.isAuthenticated()) {
      setAuthApi(authApi);
    }
  }, [authApi, setAuthApi, studioApi]);
  return <>{children}</>;
};

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!isUserLoggedIn() && !PUBLIC_URLS.includes(pathname)) {
      navigate("/login");
    }
  }, [navigate, pathname]);

  useEventListener("error", (event) => {
    const { error } = event;
    if (isSutroError(error)) {
      logError(error, console);
      event.preventDefault();
    }
  });

  return (
    <IntercomProvider appId={config.intercomAppId}>
      <OpenTelemetry
        serviceName={config.otelServiceName}
        endpoint={config.otelEndpoint}
      />
      <SutroApiProvider authGetter={retrieveAuthData}>
        <PreviewCommLayerProvider iframeRef={iframeRef}>
          <InitializeApi>
            <EntitlementsProvider>
              <Outlet />
            </EntitlementsProvider>
          </InitializeApi>
        </PreviewCommLayerProvider>
      </SutroApiProvider>
    </IntercomProvider>
  );
}

export function HydrateFallback() {
  return <p>Loading...</p>;
}

export function ErrorBoundary() {
  const error = useRouteError();
  const is404 = isRouteErrorResponse(error) && error.status === 404;

  useEffect(() => {
    if (error && !is404) {
      Sentry.captureException(error);
    }
  }, [error, is404]);

  return (
    <Suspense>
      <SutroApiProvider authGetter={retrieveAuthData}>
        <OpenTelemetry
          serviceName={config.otelServiceName}
          endpoint={config.otelEndpoint}
        />
        <StandardTopNavigationBar />
        <BelowNavigationBarFullSizeContainer>
          <PageContent className="flex h-full items-center justify-center">
            <PageStatus
              title={is404 ? "404 - Site not found" : "Something went wrong"}
              message={
                is404
                  ? "Oops! The site you’re looking for was not found."
                  : "We could not process your last request."
              }
            />
          </PageContent>
        </BelowNavigationBarFullSizeContainer>
      </SutroApiProvider>
    </Suspense>
  );
}
